@import (reference) "../../../../variables";
@import "./compact";
@import "./prefix";

.card {
  width: 100%;
  height: @10 + @7 + @05;
  margin: 0 0 (@3 + @05) 0;
  background: @navBakgrunn;
  line-height: @10 + @1 + @05;
  color: @navBla;
  box-shadow: @navBlaDarken20 0 @025 @1;
  list-style: none;
  padding-top: @3;

  .title {
    margin: 0;
    .title-combo(@10 + @1 + @05);
    padding: 0 @2;
    font-weight: 600;
    @media screen and (min-width: @med) {
      font-size: @2 + @025;
    }

    &:not(.first):before {
      content: attr(data-count) + ". ";
    }

    .subtitle {
      font-weight: 600;
      color: @navBla;
    }
  }

  &.withGiftCard {
    height: @10 + @9 + @05;
  }

  &.done {
    background: @navGronnLighten80;

    &:before {
      background: @navGronn;
    }
  }

  .saving {
    float: left;
    width: @3;
    height: @5 + @075;
    margin: 0 @1 0 @2;
    color: @navBla;
  }

  .check,
  .next,
  .locked {
    position: absolute;
    left: 50%;
    top: 100%;
    margin: (-(@2 + @025)) 0 0 (-(@2 + @025));
    width: @4 + @05;
    height: @4 + @05;
    padding: @1;
    color: @navBakgrunn;
    background: linear-gradient(@navGronn 50%, cyan 500%);
    border-radius: 50%;
    z-index: 15;
  }

  .next {
    background: linear-gradient(@navLysBla 50%, white 500%);
  }

  .locked {
    background: linear-gradient(@navBlaDarken40 50%, black 500%);
  }

  &:not(.dim):active {
    filter: brightness(0.75);
  }
}
