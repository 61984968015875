@import (reference) "../../../../variables";

.body {
  opacity: 0;
  transition: opacity 0.25s linear;
  & + .navigation {
    visibility: hidden;
  }

  &.visible {
    opacity: 1;

    & + .navigation {
      visibility: visible;
    }
  }

  .category {
    margin-top: @1;
    min-height: @05;
    font-size: @1 + @075;
  }

  h2 {
    font-weight: 600;
    margin: @2 0;
    line-height: @3 + @025;
  }

  .space {
    width: @10 + @2 + @05;
    height: @0125;
    background: transparent;
    display: inline-block;
  }

  .title {
    font-weight: 600;
    font-size: @2 + @025;
    line-height: @3;
    margin: 0;
  }

  .audio {
    border-color: transparent !important;
    padding: 0 @05;
    margin-left: -@05;
    line-height: @2;
    min-height: @3;

    &.adjusted {
      margin-top: -@3 - @05;
    }

    .sound {
      width: @3;
      height: @3;
    }
  }

  > img,
  > section img,
  > p img {
    margin: @5 auto;
    display: block;
    max-width: 100%;

    &.fullWidth {
      width: 100vw;
      max-width: calc(@max / 2);
      margin: @5 -@3;
    }

    &:not(.showMe) {
      display: none;
    }
  }

  &.greet {
    text-align: center;

    h2 {
      font-size: @3 + @075;
      margin-bottom: @3;
    }

    img {
      margin: @1 auto;
    }

    .knapp {
      margin: @3 auto @2;
      display: block;
      width: @10 + @10 + @10 + @4;
      padding: @1 0;
      text-align: center;
      border-width: @025;
    }
  }

  &.hideNavigation + .navigation {
    display: none; // TODO: Find better way to disable navigation to ensure video is viewed completely
  }

  .video {
    font-weight: 400;
  }

  mark {
    display: block;
    padding: @1 + @05 @2;
    background: @navGronnLighten80;
    border-radius: @1;
    max-width: 360px;
    margin: auto;
    // color: @navGronn;
  }

  &.assignment {
    .quote {
      margin-top: 0;
      margin-bottom: -@2;
      padding-bottom: 0;

      q {
        width: 191px;
        padding: @3 @4;
        font-style: italic;

        &:before {
          content: "";
          background-image: url(../../../common/graphics/tankeboble.svg);
          background-size: 100% 100%;
          width: 191px;
          height: 100%;
          display: block;
          position: absolute;
          z-index: -1;
          margin: -@3 -@4;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}
