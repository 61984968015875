@import (reference) "../../variables";

.milestone {
  color: @navBakgrunn;
  text-align: center;
  min-height: calc(100vh - 184px);
  background: @navBla !important;
  padding: @2 @2 @10 @2;

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: @navBla;
    z-index: -1;
  }

  &.fullscreen {
    min-height: 100vh;
    padding-top: @10 + @2;

    &.noPoints {
      max-width: @min * 1.25;
      margin: 0 auto;

      p {
        font-size: @2 + @025;
        margin: @2 0 0;
        line-height: @2 + @075;

        &:nth-of-type(2) {
          background-image: url(../common/graphics/ready.svg);
          background-position: 50% 85%;
          background-repeat: no-repeat;
          height: @10 + @10 + @10 + @10 + @5;
        }
      }
    }
  }

  h1 {
    font-weight: 600;
    margin: 0;
    padding-top: @1;
  }

  h2 {
    font-weight: 600;
    margin: @1 0 @8;
    line-height: @3 + @025;
  }

  p {
    margin: @2 0 @3;

    &.graphic {
      height: @10 + @10 + @10 + @10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: 50% 60%;
      margin-bottom: @5;

      &:after {
        content: "+ " attr(data-points) " poeng";
        display: block;
      }
    }
  }

  &.noPoints p:after {
    content: "" !important;
  }

  .knapp {
    border-color: @navBakgrunn;
  }
}
