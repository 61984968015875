@import (reference) "../../../variables";

.progress {
  height: @1;
  width: calc(100% - @4);
  position: absolute;
  top: @2;
  left: @2;
  border-radius: @05;
  background-size: @0125 @0375;

  &.alt {
    position: static;
    width: calc(100% - @8);
    margin-left: @4;
  }
}
