@import (reference) "../../../../variables";

.ingress {
  flex: 0 0 100%;
  display: table;
  padding: @2 0 0 @1;
  white-space: pre-wrap;
  background-repeat: no-repeat;
  font-size: @2 + @025;
  line-height: @2 + @075;
  margin-top: -@8;
  @media screen and (min-width: @med) {
    font-size: @3;
    line-height: @3 + @025;
    font-weight: 400;
    width: 100%;
    max-width: @max;
    margin-left: auto;
    margin-right: auto;
  }

  &.visible {
    margin-top: 0;
  }

  > span {
    display: inline-block;
    @media screen and (min-width: @med) {
      max-width: @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10;
    }
  }

  svg {
    height: auto;
    float: right;
    margin-left: -@2;
    opacity: 0;
  }

  small {
    font-size: @2;
    display: block;
    margin-top: @1;

    @media screen and (min-width: @med) {
      margin-top: @2;
    }
  }
}
