@import (reference) "../../variables";

.objective {
  padding: @6 @3 @2;
  max-width: calc(@max / 2) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: @3 0 @2 + @05;
  }

  .knapp.huge {
    width: 100%;
    height: @9 + @025;
    margin: @1 0 @2;
    overflow: hidden;
    background-image: url(../common/graphics/jobb.svg);
    background-size: @10 + @3 + @05;
    background-repeat: no-repeat;
    background-position: right @1 bottom -@05;

    &.knapp--hoved {
      background-position: right @1 + @0125 bottom -@05 + @0125;
    }

    &:nth-of-type(2) {
      background-image: url(../common/graphics/utdanning.svg);
      background-position: right @1 + @025 bottom -@2 + @05;

      &.knapp--hoved {
        background-position: right @1 + @025 + @0125 bottom -@2 + @05 + @0125;
      }
    }
  }

  svg {
    height: @10 + @6;
    width: auto;
    display: block;
    margin: @10 auto 0;
  }

  nav {
    margin-top: auto;
  }
}
