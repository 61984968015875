@import (reference) "../../../../variables";

.register {
  padding: @3;
  max-width: calc(@max / 2) !important;

  .info {
    background: @navGraBakgrunn;
    padding: 0 @2;
    display: inline-block;
    margin: -@1 -@1 @3;
    border-radius: @1 + @025;
    @media screen and (min-width: @med) {
      margin-top: @2;
    }
  }

  .red {
    color: crimson;
  }

  .skjemaelement__label,
  .skjemagruppe.inputPanelGruppe .skjemagruppe__legend {
    font-weight: normal;
    margin: @05 0;
  }

  .skjemaelement__input {
    line-height: @5;
    font-size: @3;
  }

  .skjemagruppe.inputPanelGruppe {
    margin-top: @2;

    .inputPanel {
      width: calc(50% - @025);
      display: inline-block;

      &:nth-child(2) {
        margin-left: @05;
      }
    }
  }

  > strong {
    font-size: @2 + @025;
    line-height: @3;
    margin-top: @3;
    display: block;
  }

  .alertstripe {
    margin-top: @4;
  }
}
