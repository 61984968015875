@import (reference) "../../../../../variables";

.assignmentBlock {
  margin-top: @5;

  p > img {
    margin: 0 !important;
    margin-top: 2rem !important;
  }

  textarea {
    height: @10 + @4 !important;
  }

  .big textarea {
    height: @10 + @10 + @10 + @2 !important;
  }

  p + div {
    margin-top: -@1;
  }

  i {
    margin-top: -@2;
    display: block;
  }

  .teller-tekst {
    display: none;
  }

  .stars {
    width: 100%;
    text-align: center;

    .star {
      font-size: @4;
      margin: 0 @1;
      cursor: pointer;
      color: @navGra20;

      &.selected {
        color: gold;
      }
    }

    &.disabled {
      cursor: not-allowed;

      .star {
        pointer-events: none;
      }
    }
  }

  .picker {
    padding: 0;

    .pick {
      margin-bottom: @1;
      list-style-type: none;
      width: 50%;
      height: @10 + @10 + @1;
      border-radius: @2;
      display: block;
      float: left;
      text-align: center;
      cursor: pointer;

      .image {
        margin-top: @1;
        width: @10 + @2 + @05;
        height: @10 + @2 + @05;
        background-size: cover;
        background-position: 50%;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.25) 0 @05 @05;
        display: inline-block;
      }

      strong {
        color: @navBla;
        display: block;
      }

      &.selected {
        background: @navBla;
        color: @navBakgrunn;

        strong {
          color: @navBakgrunn;
        }
      }
    }

    &.multiple {
      .pick {
        width: 100%;
        height: auto;
        float: none;
        border-radius: @025;
        text-align: left;
        padding: @2;

        &:nth-child(1) {
          background: @navLysBlaLighten90;
          border: @0125 solid @navBla;

          &.selected {
            background: @navBla;
          }
        }

        &:nth-child(2) {
          background: @navOransjeLighten90;
          border: @0125 solid @navOransje;

          &.selected {
            background: @navOransje;
          }
        }

        &:nth-child(3) {
          background: @navLysGronnLighten90;
          border: @0125 solid @navGronn;

          &.selected {
            background: @navGronn;
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;

      .pick {
        color: gray;
      }

      .pick *:not(.image) {
        filter: saturate(0);
      }

      .pick.selected {
        background: lightgray;

        strong {
          color: @navBla;
        }
      }

      &.multiple .pick {
        background: @navBakgrunn;
        border-color: gray;
        color: gray;
        &.selected {
          background: lightgray;
        }
      }
    }
  }

  .timePicker,
  .stars,
  .picker,
  .sound,
  .choice {
    & + .skjemaelement {
      display: none;
    }
  }

  .sound + div + p img {
    margin: 2rem auto !important;
  }

  .picker, .linkRadios {
    & + .skjemaelement + p {
      display: none;
    }
  }

  .skjemagruppe.inputPanelGruppe:not(.full) {
    margin-top: @2;

    .inputPanel {
      width: calc(50% - @025);
      display: inline-block;

      &:nth-child(2) {
        margin-left: @05;
      }
    }
  }

  p a[href$="help"], p a[href$="support"], p a[href$="after"] {
    text-decoration: none;
    color: @navTekstfarge !important;
    background: hsla(209, 100%, 39%, 0.21);
    display: block;
    line-height: @6;
    margin-bottom: -@2;
    padding-left: @2;

    &[href$="support"] {
      background: hsla(112, 59%, 81%, 0.38);
    }

    &[href$="after"] {
      background: hsla(250, 100%, 69%, 0.16);
    }
  }
}

h2 + .assignmentBlock,
h2 + p + .assignmentBlock {
  margin-top: @2;

  i {
    margin-top: 0;
    display: initial;
  }
}
