@import (reference) "../../../../../variables";

.example {
  background: @navLysBlaLighten90;
  margin: 0 -@3 @1;
  padding: @3 @3 @1 @3;

  .title {
    font-weight: 900;
  }

  .image {
    margin-left: @1;
    margin-top: -@8;
    margin-bottom: @2;
    width: @10 + @2 + @05;
    height: @10 + @2 + @05;
    background-size: cover;
    background-position: 50%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0 @05 @05;
    float: right;
  }
}
