@import (reference) "../../../variables";
@import "./head";
@import "./input-range";

.mood {
  padding: @2 @3;

  h2 {
    margin: @2 0;
    font-weight: 600;
    line-height: @3 + @025;
  }

  p {
    font-size: @3;
    line-height: @3 + @025;
    text-align: center;
    font-weight: 600;
  }

  .inputWrapper {
    padding: @1 @2 @4;
  }

  .knapp {
    display: block;
    margin: @2 auto;
  }
}
