@import (reference) "nav-frontend-core";
@import (reference) "../../../variables";

.knapp:extend(.knapp) {
  text-transform: none;
  letter-spacing: 0;
  border-radius: @025;
  padding-top: 0;
  padding-bottom: 0;

  &.knapp--standard {
    border-color: @navBla;
    background-color: transparent;

    &:hover,
    &:focus {
      color: @navBla;
    }
  }

  &:first-child {
    justify-self: flex-start;
  }

  &:not([class*="--kompakt"]) {
    padding-left: @3;
    padding-right: @3;
  }

  svg {
    width: @2 + @025;
    height: @2 + @025;
  }
}
