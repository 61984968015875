@import (reference) "../../variables";

.loader {
  height: @025;
  border-radius: @0125;
  background: @navBakgrunn;
  position: fixed;
  left: @2;
  bottom: @2;
  animation: loader linear forwards;
  overflow: visible;
  opacity: 0.5;
  pointer-events: none;
  mix-blend-mode: overlay;
}

@keyframes loader {
  0% {
    width: 0;
  }
  100% {
    width: calc(100% - @4);
  }
}
