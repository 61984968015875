@import (reference) "../../../variables";

.page {
  max-width: calc(@max / 2) !important;
  // min-height: calc(100vh - @10 - @4);
  min-height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;
  padding: @2 @3;

  video {
    width: 100vw;
    max-height: 100%;
    max-width: 100%;
    margin-top: @2;
  }

  .knapp:not(.audio):not(.survey):not(.video) {
    margin: @5 1%;
    width: 48%;
  }

  .spinner {
    position: fixed;
    top: @10 + @10 + @10;
    left: calc(50% - @2);
  }
}
