.title-combo(@height) {
  display: inline-block;
  line-height: (@height / 4);
  position: relative;
  top: (@height / 8);

  .subtitle {
    color: @navGra80;
    display: block;
    line-height: (@height / 4);
  }
}

.quote-color(@color) {
  background: @color;

  &::after {
    border-left-color: @color;
  }

  &.right::after {
    border-right-color: @color;
  }
}
