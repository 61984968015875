@import (reference) "../../../variables";

.header.light {
  nav {
    @media screen and (min-width: @med) {
      max-width: @max;
    }
  }

  .ingress {
    background-image: url(../graphics/skrivebord.svg);
    background-size: @10 + @10 + @4;
    background-position: bottom -@1 right 0;
    @media screen and (min-width: @med) {
      background-size: @10 + @10 + @10 + @10 + @075;
      background-position: bottom -@2 right 0;
    }

    svg {
      width: @10 + @10 + @4;
      margin-right: 0;
      margin-bottom: -@1;
      @media screen and (min-width: @med) {
        width: @10 + @10 + @10 + @10 + @075;
        margin-bottom: -@2;
      }
    }
  }
}
