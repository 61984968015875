@import (reference) "../../../../variables";

.settingsPage {
  padding: @1 @3 @3;
  max-width: calc(@max / 2) !important;

  h1 {
    font-weight: 600;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: @3 0 @3;

    + a {
      text-decoration: none;
    }
  }

  img {
    width: 100%;
    max-width: 458px;
    margin: 0 0 @3;
  }

  ul {
    padding: 0;
    margin: @4 -@3 0;
  }

  .menu li {
    padding: @3 @2;
    margin-bottom: @1;
    list-style-type: none;
    display: block;
    background: @navLysBlaLighten90;
    font-weight: 600;
    cursor: pointer;

    strong {
      display: block;
      font-size: @2 + @025;
    }
  }

  p.loggedInAs {
    font-size: @2 + @025;
  }

  .assignmentBlock {
    margin-top: @2;
  }

  .confirmation {
    color: @navGronn;
    margin-left: @2;
    opacity: 1;
    transition: opacity 1s ease-in;

    &.fade {
      opacity: 0;
    }
  }

  .weeklyMood {
    width: 25%;
    display: inline-block;
    text-align: center;

    svg {
      display: block;
      margin: 0 auto @075;
      width: 60px;
      height: auto;
    }
  }

  .greenBox {
    background: @navGronnLighten80;
    border-radius: @075;
    padding: @2;
    width: 100%;
    margin: @6 0;
  }

  .blueBox {
    background: @navLysBlaLighten90;
    margin: @4 -@3 0;
    padding: @4 @3;
  }
}

.svgWrapper {
  float: left;
  display: flex;
  margin-right: @1 + @05;
  height: 49px;
  width: 49px;
  align-items: center;
  justify-content: center;
}

.contactBox {
  background: @navLysBlaLighten90;
  padding: @4 @2;
  max-width: calc(@max / 2);
  margin: 0 auto;
  z-index: 9;
  position: relative;

  .svgWrapper {
    margin-bottom: @5;
  }
}
