@import (reference) "../../../../variables";

.techniques {
  padding: @4 @3;
  max-width: calc(@max / 2) !important;
  @media screen and (min-width: @med) {
    padding-top: @8;
  }

  h2 {
    font-weight: 600;
    margin: 0;
    line-height: @3 + @025;
  }
}
