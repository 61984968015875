@import (reference) "../../../variables";

.header.dark {
  color: @navBakgrunn;
  background-color: @navBla;

  .knapp {
    border: @025 solid @navBakgrunn;
  }

  .ingress {
    background-image: url(../graphics/kvinneogmann.svg);
    background-size: @10 + @10 + @1;
    background-position: bottom -@0125 right -@2;
    @media screen and (min-width: @med) {
      background-size: @10 + @10 + @10 + @10 + @075;
    }

    svg {
      width: @10 + @10 + @1;
      margin-right: -@2;
      margin-bottom: -@0125;
      @media screen and (min-width: @med) {
        width: @10 + @10 + @10 + @10 + @075;
      }
    }
  }
}
