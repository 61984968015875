@import "variables";
@import "utilities";
@import "nav-frontend-core";
@import "nav-frontend-typografi-style/src/fontimports";

body {
  background: @navBakgrunn;
}

:root {
  font-size: @2;

  .app {
    width: 100vw;
    height: 100vh;
    min-width: @min;
    color: @navTekstfarge;

    a:not(.knapp) {
      color: @navBla;
    }

    > section {
      max-width: @max;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
