@import (reference) "../../variables";
@import "./nudge";
@import "./loader";

.splash {
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow: scroll;
  top: 0;
  left: 0;
  background: @navBla;
  text-align: center;
  color: @navBakgrunn;
  padding-bottom: @10 + @6;
  animation: reveal 0.5s ease-in-out forwards;
  opacity: 0;

  h1 {
    font-weight: 600;
    font-size: @10 + @2 + @05;
    line-height: @10 + @6;
    letter-spacing: @05 + @025;
    margin: @8 auto 0;
    text-align: left;
    text-transform: uppercase;
    width: @10 + @10 + @10 + @10 + @3 + @05;
    max-width: calc(100% - @6);
    @media screen and (min-width: @med) {
      width: @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10 + @3 + @05;
    }
  }

  img {
    width: @10 + @10 + @10 + @10 + @3 + @05;
    max-width: calc(100% - @2);
    @media screen and (min-width: @med) {
      width: @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10 + @3 + @05;
    }
  }

  div {
    font-weight: 300;
    text-align: left;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: 0 auto @3;
    width: @10 + @10 + @10 + @10 + @1 + @05;
    max-width: calc(100% - @4);
    @media screen and (min-width: @med) {
      width: @10 + @10 + @10 + @10 + @10 + @10 + @10 + @10 + @1 + @05;
    }

    &:nth-of-type(2) {
      margin-top: @3;
    }

    p {
      margin: 0;
    }
  }

  &.hideNudge .nudge {
    opacity: 0;
  }

  .knapp {
    margin-top: @4;
    border-color: @navBakgrunn;
  }

  small {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: @1 + @05;
    line-height: @3;

    a {
      color: @navBakgrunn !important;
    }
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
