@import (reference) "../../../variables";

.chapters {
  margin: 0;
  padding: @2;
  background: @navBla;
  @media screen and (min-width: @med) {
    max-width: @med;
    margin: 0 auto;
    padding: @6 @2;

    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: @navBla;
      z-index: -1;
    }
  }
}
