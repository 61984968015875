@import (reference) "../../../../variables";

.card.compact:first-of-type {
  height: @5 + @025;
  line-height: @5 + @025;

  .title {
    .title-combo(@7 + @025);
  }

  &:before {
    text-align: center;
    background: @navLysBlaLighten90;
    background-image: url(../../../common/graphics/arrowright.svg);
    background-position: center;
    background-repeat: no-repeat;
    font-size: @3 + @075;
    float: left;
    content: "";
    height: @5 + @025;
  }

  .next {
    height: @5 + @025;
    margin: 0 @2 calc(-@5 - @025);
  }

  .check {
    width: @9;
    height: @5 + @025;
    padding: 0 @2;
  }
}
