@import (reference) "../../../variables";
@import "./alternative";

.quote {
  overflow: hidden;
  padding-bottom: @4;
  margin-bottom: -@1;
  display: block;

  q {
    display: block;
    background: @navGra20;
    width: @10 + @10 + @8;
    padding: @1 + @025;
    border-radius: @1;
    clear: both;
    text-align: center;
    position: relative;
    z-index: 1;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    &:before {
      content: "";
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -@4;
      left: 50%;
      margin-left: -(@3 + @075);
      z-index: -1;
      width: 0;
      height: 0;
      border-top: @4 solid transparent;
      border-bottom: @4 solid transparent;
      border-left: @7 + @05 solid @navGra20;
    }

    &.right:after {
      border-left: unset;
      border-right: @7 + @05 solid @navGra20;
    }

    &.blue {
      .quote-color(@navLysBlaLighten90);
    }

    &.yellow {
      .quote-color(@navOransjeLighten90);
    }

    &.green {
      .quote-color(@navLysGronnLighten90);
    }

    &.pink {
      .quote-color(@redErrorLighten90);
    }
  }
}
