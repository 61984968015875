@import (reference) "../../../../variables";

.coinDrop {
  display: inline-block;
  position: relative;

  svg {
    position: relative;
    z-index: 3;
  }

  div {
    position: absolute;
    z-index: 1;
    display: block;
    height: @1 + @025;
    width: @1 + @025;
    background: @navBla;
    border: @025 solid #fff;
    border-radius: 50%;
    opacity: 1;
    animation-name: coinDrop;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    &:nth-of-type(1) {
      top: -114px;
      right: 20px; // 65
      animation-duration: 1.98s;
    }

    &:nth-of-type(2) {
      top: -96px;
      right: 15px; // 72
      animation-duration: 1.55s;
    }

    &:nth-of-type(3) {
      top: -80px;
      right: 27px; // 57
      animation-duration: 1.34s;
    }

    &:nth-of-type(4) {
      top: -72px;
      right: 21px; // 66
      animation-duration: 0.92s;
    }

    &:nth-of-type(5) {
      top: -66px;
      right: 25px; // 60
      animation-duration: 0.6s;
    }
  }
}

@keyframes coinDrop {
  0% {
    height: 10px;
    opacity: 1;
  }
  95% {
    height: 10px;
    width: 10px;
    opacity: 1;
  }
  100% {
    top: 15px;
    height: 0;
    width: 0;
    opacity: 0;
  }
}
