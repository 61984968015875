@import (reference) "../../../variables";

.summary {
  background: @navLysBlaLighten90;
  overflow: visible;
  margin: 0 -@3 @3;
  padding: @1 @2 @1 @10 + @3 + @05;

  * {
    position: relative;
    z-index: 1;
  }

  .title {
    font-size: @2 + @025;
    line-height: @3;
    margin-bottom: 0;

    &:before {
      content: "";
      height: @9 + @05;
      width: @9 + @05;
      border-radius: 50%;
      background: @navBakgrunn;
      display: block;
      float: left;
      text-align: center;
      margin-left: -@10 - @1 - @05;
      padding-top: @2 - @0125;
    }

    &:nth-of-type(1):before,
    &:nth-of-type(5):before {
      content: url("../graphics/fillforms.svg");
    }

    &:nth-of-type(3):before {
      content: url("../graphics/mobile.svg");
    }

    &:nth-of-type(7):before {
      content: url("../graphics/money.svg");
      padding-top: @2 - @025;
    }

    + span + p {
      margin-top: @1 + @05;

      &:not(.last) {
        min-height: @10;
      }
    }
  }

  .arrow {
    height: @10;
    line-height: @10;
    width: @9 + @05;
    font-size: @7 + @05;
    text-align: center;
    margin-left: -@10 - @1 - @05;
    margin-top: @6;
    float: left;
    position: relative;
    z-index: 2;
    pointer-events: none;
    color: @navLysBlaDarken20;

    &:last-of-type {
      display: none;
    }
  }
}
