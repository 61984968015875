@import (reference) "../../../../variables";

.card.compact {
  height: @9;
  line-height: @9;
  margin: (@1) 0;
  overflow: hidden;
  box-shadow: none;
  padding: 0;

  .title {
    .title-combo(@9);
    width: calc(100% - @10 - @5 - @075);
    padding: 0;

    &:before {
      content: "";
    }

    .subtitle {
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &.withGiftCard {
    height: @10 + @4 + @075;
  }

  &:before {
    text-align: center;
    background: @navLysBlaLighten90;
    font-size: @3 + @075;
    float: left;
    content: attr(data-count);
    width: @9;
    height: @9;
    margin-right: @075;
  }

  .check {
    left: 0;
    top: 0;
    margin: 0;
    width: @9;
    height: @9;
    padding: @2;
    border-radius: 0;
    background: radial-gradient(@navGronn 50%, cyan 500%);
  }

  .next,
  .locked {
    position: static;
    float: right;
    height: @9;
    color: @navGra80;
    border-radius: 0;
    background: none;
    padding: 0;
  }

  .next {
    width: @2;
    margin: 0 @2 -@9;
  }

  .locked {
    width: @3;
    margin: 0 (@1 + @05) -@9;
  }
}
