@import (reference) "../../../../variables";

.addendum {
  padding: @2 @3;
  // min-height: calc(100vh - @10 - @4);
  min-height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;

  h2 {
    margin: @2 0;
    font-weight: 600;
    line-height: @3 + @025;
  }

  p {
    margin: @1 0;
  }
}
