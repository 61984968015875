@import (reference) "../../../variables";

.head {
  width: @10 + @10 + @5;
  height: @10 + @10 + @5;
  background: @navOransjeLighten90;
  border-radius: 50%;
  margin: @6 auto @3;

  .leftEye,
  .rightEye {
    transition: transform 0.2s ease-in-out;
    fill: @navTekstfarge;
  }

  .leftEye.happy {
    transform: translate(@05, -@05);
  }

  .rightEye.happy {
    transform: translate(0, -@05);
  }

  .rightEye.duchenne {
    transform: translate(-@05, 0);
  }

  .nose,
  .mouth {
    stroke: @navTekstfarge;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: transparent;
  }

  .nose {
    transition: 0.2s ease-in-out;

    &.relaxed {
      transform: translate(@1, @1);
    }
  }

  .sweat {
    transition: opacity 0.2s ease-out, transform 0.001s ease-out 0.3s;
    fill: @navBakgrunn;
    opacity: 0;
    transform: translateY(-@2);

    &.drip {
      transition: 1s ease-out;
      opacity: 1;
      transform: translateY(@2);
    }
  }
}
