@import (reference) "../../../variables";
@import "./dark";
@import "./light";

.header {
  width: 100vw;
  min-width: @min;
  padding: @7 @2 0;
  background-color: @navLysBlaLighten90;
  color: @navBla;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: background-color 0.25s ease-in-out;
  @media screen and (min-width: @med) {
    display: block;
    padding-top: @10 + @6;
  }

  nav {
    @media screen and (min-width: @med) {
      margin: -@5 auto 0;
      text-align: right;
    }

    .knapp {
      &:nth-child(1) {
        min-width: @9 + @05;
      }
      &:nth-child(n + 2) {
        margin-left: @1;
      }
    }
  }

  h1 {
    font-weight: 600;
    margin: 0;
    line-height: @4 + @025;
    padding: @0375 @1;
    @media screen and (min-width: @med) {
      letter-spacing: 6px;
      line-height: @9;
      font-size: @7 + @05;
      max-width: @max;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.collapsed {
    padding-bottom: @2;
    @media screen and (min-width: @med) {
      h1 {
        text-align: center;
      }
    }

    .ingress {
      height: 0;
    }
  }

  & + section {
    position: relative;
  }
}
