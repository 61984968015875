@import (reference) "../../../variables";

.home {
  padding: @3 @3 @6 @3;
  max-width: @max + @4 !important;
  @media screen and (min-width: @med) {
    padding-top: @5;

    p {
      font-size: @2 + @025;
      line-height: @3;
      max-width: 450px;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (min-width: @med) {
      display: block;
      margin-left: 0;
      position: absolute;
      top: -@10 - @6;
      z-index: 999;
    }

    .knapp {
      margin-top: @1;
      margin-bottom: @1;
      white-space: pre;

      &:nth-child(2) {
        background: @navBakgrunn !important;
        color: @navBla;
        border-color: @navBakgrunn;
      }

      @media screen and (min-width: @med) {
        margin-right: @2;
        width: @10 + @10 + @1;
        background: @navBakgrunn;
        border-color: @navBakgrunn;

        &:nth-child(3) {
          background: @navBla;
          color: @navBakgrunn;
          border-color: @navBla;
        }
      }
    }
  }

  > .knapp {
    margin: @6 auto;
    display: block;
  }

  img {
    height: @10 + @6 + @0375;
    margin: 0 auto;
    display: block;
    @media screen and (min-width: @med) {
      float: right;
      margin-top: -@10 - @10;
    }
  }
}
