@import (reference) "../../../../../variables";

.overview {
  padding: 0;
  margin: 0;
  background: linear-gradient(90deg, white 1px, @navBla 2px);
  box-shadow: @navBakgrunn inset 0 @2 0, @navBakgrunn inset @05 0 0,
    @navBakgrunn inset calc(-100vw + @6 + @05 + @0125) 0 0;
  @media screen and (min-width: 560px) {
    box-shadow: @navBakgrunn inset 0 @2 0, @navBakgrunn inset @05 0 0,
      @navBakgrunn inset calc(-560px + @6 + @05 + @0125) 0 0;
  }
  @media screen and (max-width: 320px) {
    box-shadow: @navBakgrunn inset 0 @2 0, @navBakgrunn inset @05 0 0,
      @navBakgrunn inset calc(-320px + @6 + @05 + @0125) 0 0;
  }

  li {
    list-style-type: none;
    margin: 0;
    padding: @05 0 @2 + @05 @2;
    border-radius: @05;

    strong {
      display: block;

      &:before {
        width: @1 + @0125;
        height: @1 + @0125;
        margin-right: @1 - @0125;
        margin-left: -@2;
        background: @navBla;
        position: relative;
        top: -@0125;
        display: inline-block;
        border-radius: 50%;
        content: "";
      }
    }

    &:last-of-type strong:after {
      width: @1 + @0125;
      height: @10 + @10;
      background: @navBakgrunn;
      margin-left: -@2;
      margin-top: -7.5px;
      content: "";
      display: block;
      position: absolute;
    }

    &.compact {
      padding: 0 0 @1 @2;

      &:before {
        width: @1 + @0125;
        height: @1 + @0125;
        margin-right: @1 - @0125;
        margin-left: -@2;
        background: @navBakgrunn;
        border: @0125 solid @navBla;
        position: relative;
        top: -@0125;
        display: inline-block;
        border-radius: 50%;
        content: "";
      }
    }
  }
}
