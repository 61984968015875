@import (reference) "../../../../variables";

.feedback {
  padding: @2 @3;
  // min-height: calc(100vh - @10 - @4);
  min-height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;

  p {
    margin: 0 auto @1;
    width: @10 + @10 + @10 + @10 + @1 + @0375;
    padding: 0 @10 + @4 0 @2 + @05;
    z-index: 1;

    &:last-of-type {
      min-height: 80px;
    }
  }

  svg {
    filter: drop-shadow(2px 10px 20px rgba(0, 0, 0, 0.075));
    margin-bottom: -@10 - @10 - @1 - @0375;
    width: 100%;
  }
}
