@import (reference) "../../variables";
@import "./card";

.giftCard {
  background: @navBakgrunn;
  padding: @1 + @05 @2;
  margin-bottom: @4;
  opacity: 0;
  transition: opacity 1s;

  &.visible {
    opacity: 1;
  }

  p {
    margin: 0;
    font-weight: 600;
    color: @navBla;
    line-height: @2;
    padding-left: @7;

    span {
      color: @navGra80;
    }
  }

  svg {
    float: left;
    margin-top: -@05;
  }

  .progress {
    position: static;
    width: calc(100% - @7);
    margin: @05 0 @025 @7;
  }
}

.giftCardPage {
  padding: @1 @3 @3;
  max-width: calc(@max / 2) !important;

  h1 {
    font-weight: 600;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: @3 0 @3;

    + a {
      text-decoration: none;
    }
  }

  ul {
    padding: 0;
    margin: @4 0 0;
  }
}
