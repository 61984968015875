@import (reference) "variables";

.soft {
  border-radius: @025;
}

.softer {
  border-radius: @1;
}

@appear: opacity 0.125s, bottom 0.125s;

.base {
  cursor: auto;
  position: relative;
  bottom: 0;
  transition: @appear;
}

.off:extend(.base) {
  opacity: 0;
  bottom: @05;
}

.dim:extend(.base) {
  .dimmable::before {
    content: "";
    width: 100%;
    height: 100%;
    background: @navBla;
    opacity: 0.5;
    display: block;
    position: absolute;
    z-index: 9;

    @media screen and (min-width: @med) {
      margin-top: -@3;
      height: calc(100% + @3);
    }
  }
}

.on:extend(.base) {
  .dimmable::before {
    display: none;
  }

  cursor: pointer;
}
