@import (reference) "../../variables";

.giftCardCard {
  box-shadow: rgba(0, 0, 0, 0.25) 0 @05 @05;
  display: block;
  border-radius: @05;
  padding: @3;
  overflow: hidden;
  margin-bottom: @4;

  h2 {
    background: @navGronnLighten80;
    border-bottom: solid @05 @navBlaLighten60;
    margin: -@3 -@3 @2 -@3;
    font-size: @2 + @025;
    text-align: center;
    padding: @2;
    line-height: 1em;

    &:before {
      content: attr(data-value);
      display: block;
      font-weight: normal;
      color: @navBakgrunn;
      height: @2;
      line-height: @2;
      margin-bottom: -@2;
      position: relative;
      top: @4 + @075;
      font-size: @2;
    }

    svg {
      width: @7;
      height: auto;
      margin-bottom: @1;
    }
  }

  p {
    margin: @1 0;
  }

  button {
    margin: @3 auto;
    display: block;
  }
}
