@import (reference) "../../../variables";

.status {
  margin: 0;
  font-size: @1 + @075;
  height: @5 + @075;
  line-height: @5 + @075;
  background: @navLysBlaLighten90;
  padding-left: 0; // @7 + @075
  color: @navTekstfarge;
  // position: relative;
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: inherit;

  svg path {
    fill: @navTekstfarge;
  }

  &.fulfilled {
    background: @navGronnLighten60;
    color: @navGronn;

    svg path {
      fill: @navGronn;
    }
  }

  &:not(.compact) {
    border-bottom-left-radius: @1;
    border-bottom-right-radius: @1;
  }
}
