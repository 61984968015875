@import (reference) "../../variables";

.nudge {
  // height: @10 + @10;
  height: @10 + @5;
  // line-height: @10 + @10;
  width: 100%;
  background: linear-gradient(transparent, @navBla);
  transition: opacity 0.5s ease-in-out;
  position: fixed;
  left: 0;
  bottom: 0;
  // font-size: @7 + @05;
  opacity: 1;
  // pointer-events: none;
  color: rgba(255, 255, 255, 0.75);
}
