@import (reference) "../../../variables";

.basicWizardNavigation {
  margin-top: auto;

  .knapp {
    margin: @5 1%;
    width: 48%;
  }
}
