@import (reference) "../../../variables";

.quote.alternative {
  padding: 0;
  margin-bottom: @2 + @05;

  q,
  q[class*="blue"],
  q[class*="yellow"],
  q[class*="green"],
  q[class*="pink"] {
    background: transparent;
    width: 100%;
    padding: 0;
    text-align: left;
    font-size: @2 + @025;
    font-style: italic;
    font-weight: 300;

    &:after {
      display: none;
    }
  }
}
