@import (reference) "../../../../variables";

.dots {
  display: block;
  line-height: @1 + @0125;

  .dot {
    width: calc(@1 + @0125);
    height: calc(@1 + @0125);
    border: solid @0125 @navBla;
    border-radius: 50%;
    display: inline-block;

    &.filled {
      background: @navBla;
    }

    &:not(:last-child) {
      margin-right: @2 + @05;

      &:after {
        content: "";
        width: @2 + @05;
        height: @0125;
        background: @navBla;
        display: block;
        position: relative;
        top: calc(50% - 0.5px);
        left: @1;
      }

      @media screen and (max-width: @med) {
        margin-right: @2;

        &:after {
          width: @2;
        }
      }
    }
  }

  &.many .dot:not(:last-child) {
    margin-right: @1;

    &:after {
      width: @1;
    }

    @media screen and (max-width: @med) {
      margin-right: 0.5px;

      &:after {
        width: 0.5px;
      }
    }
  }
}
