@import (reference) "../../variables";

.notFound {
  background: @navLysBlaLighten90;
  text-align: center;
  height: 100%;

  h1 {
    font-weight: 600;
    font-size: @10 + @2 + @05;
    line-height: @10 + @2 + @05;
    margin: 0;
    padding-top: @5;
    color: @navBla;
  }

  p:nth-of-type(1) {
    font-weight: 600;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: 0 auto @3;
    width: @10 + @10 + @10 + @7 + @05;
    max-width: calc(100% - @4);
  }

  .knapp {
    margin-top: @3;
    font-weight: 400;
  }

  svg {
    display: block;
    margin: 0 auto;
    max-width: calc(100% - @2);
  }
}
