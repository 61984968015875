@import (reference) "../../../../variables";

.privacy {
  &.padded {
    padding: @4 @3;

    @media screen and (min-width: @med) {
      padding-top: @8;
    }
  }

  padding-top: @4;
  
  max-width: calc(@max / 2) !important;

  &.centered {
    text-align: center;
  }

  h1 {
    font-weight: 600;
    font-size: @3 + @075;
    line-height: @4 + @025;
    margin: @3 0 @4;
  }

  h2 {
    font-weight: 600;
    margin: 0;
    line-height: @3 + @025;
  }

  .title {
    font-size: @2 + @025;
    line-height: @3;
    margin-bottom: 0;
    font-weight: bold;
  }

  img {
    width: @10 * 2;
    margin: @3 auto;
    display: block;
  }

  svg {
    margin: 0 0 @4;
  }

  .highlight {
    background: @navLysBlaLighten90;
    margin: -@1 - @05 -@3 0;
    padding: @1 + @05 @3 @2;

    strong {
      font-weight: 600;
    }

    .knapp {
      margin: @1 auto @2;
      display: block;
      width: @10 + @10 + @10;
      padding-top: @1;
      padding-bottom: @1;
      text-align: center;
      border-width: @025;
    }

    & + nav {
      margin-top: @4;

      button {
        width: 50%;
        margin: 0 auto;
        display: block;
      }

      button:nth-child(2) {
        display: none;
      }
    }
  }
}
